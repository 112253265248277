import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";

import Loader from "./components/Loader";
import router from "./router";

function App() {
  const RenderLoader = () => <Loader open={true} />;

  return (
    <Suspense fallback={<RenderLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
