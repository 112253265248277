import { Audio } from "react-loader-spinner";
import classes from "./index.module.scss";

const Loader = ({ open }) => {
  return (
    <div className={classes.modal}>
      <Audio
        height="100"
        width="100"
        color="#c9e1fa"
        ariaLabel="audio-loading"
        visible={true}
      />
    </div>
  );
};

export default Loader;
