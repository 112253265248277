import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { lazy } from "react";

const Home = lazy(() => import("./pages/Main"));
const PrivacyPolicy = lazy(() => import("./pages/Policy"));
const TermsOfService = lazy(() => import("./pages/Terms"));
const Layout = lazy(() => import("./components/Layout"));
const NotFound = lazy(() => import("./pages/NotFound"));

const ErrorBoundary = () => {
  return <div>Uups.. Something was wrong</div>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
      </Route>
    </Route>
  )
);

export default router;
